import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function RealEstate() {
  return (
    <main>
      <div className="bg-gray-900 pt-10 sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-14">
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
              <div className="lg:py-24">
                <span className="block text-sm font-semibold uppercase tracking-wide text-gray-200 sm:text-base lg:text-sm xl:text-base">
                  Real Estate Auctions
                </span>

                <h1 className="mt-4 text-4xl font-extrabold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-2 xl:text-6xl">
                  <span className="block">A better way to</span>
                  <span className="block text-brand-red-800">
                    sell your house
                  </span>
                </h1>
                <p className="mt-3 text-left text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  Auctioning Real Estate provides for open bidding, which allows
                  bidders to make multiple offers on your house and maximize
                  your final sale price. We list our properties on MLS,
                  realtor.ca, international online auction sites, social media
                  and sent out to our extensive mailing list of interested
                  buyers.
                </p>
                <p className="mt-3 text-left text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  The high bid is subject to final approval by you, the seller.
                  It's a win/win, you can't lose.
                </p>

                <div className="mx-auto mt-5  sm:flex sm:justify-center md:mx-0 md:mt-8 lg:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href="tel:519-282-8044"
                      className="flex w-full items-start justify-center  rounded-md border border-transparent bg-brand-red-800 px-8 py-3 text-center text-base font-medium text-gray-100 hover:bg-brand-red-900 md:py-4 md:px-10 md:text-lg"
                    >
                      Call Mark: 519-282-8044
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-12 pb-12 lg:relative lg:m-0 ">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                <StaticImage
                  src="../images/cosens-auctions.png"
                  quality={95}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Cosens Auctions"
                  className="w-full  lg:inset-y-10 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
