import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import PropertyCard from './propertyCard';

function SingleProperty() {
  return (
    <StaticQuery
      query={graphql`
        query ServicesQuery {
          data: allMarkdownRemark(
            filter: {
              frontmatter: {
                property: {}
                type: { eq: "property" }
                status: { eq: "open" }
              }
            }
            sort: { fields: frontmatter___property___price, order: DESC }
          ) {
            edges {
              node {
                frontmatter {
                  property {
                    address
                    price
                    description
                    close_date
                    bidding
                    url {
                      auction
                      video
                      mls
                    }

                    mls_num
                    details {
                      name
                      items
                    }
                    images {
                      src {
                        childImageSharp {
                          gatsbyImageData(width: 400)
                        }
                      }
                      name
                      id
                      alt
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ data }) => {
        const properties = [];

        for (const { node: single } of data.edges) {
          properties.push(single.frontmatter.property);
        }

        return (
          <>
            {Object.keys(properties).map((key) => (
              <PropertyCard key={key} property={properties[key]} />
            ))}
          </>
        );
      }}
    />
  );
}

export default SingleProperty;
