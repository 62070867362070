import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function PastAuctions() {
  return (
    <div className="mt-1 bg-gray-50">
      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
          Past Auctions
        </h2>

        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
          <div className="group relative">
            <div className=" overflow-hidden rounded-lg ">
              <StaticImage
                src="../images/property/82Mand.png"
                className="object-cover object-center"
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Cosens Auctions"
                aspectRatio={4 / 3}
              />
            </div>
          </div>
          <div className="group relative">
            <div className=" overflow-hidden rounded-lg ">
              <StaticImage
                src="../images/property/44Todd.png"
                className="object-cover object-center"
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Cosens Auctions"
                aspectRatio={4 / 3}
              />
            </div>
          </div>
          <div className="group relative">
            <div className=" overflow-hidden rounded-lg">
              <StaticImage
                src="../images/property/2park.png"
                className="object-cover object-center"
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Cosens Auctions"
                aspectRatio={4 / 3}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
