import * as React from 'react';
import FeatureAuction from '../components/feature-auction';
import Layout from '../components/layout';
import PastAuctions from '../components/past-auctions';
import RealEstate from '../components/realestate';

import Seo from '../components/seo';
import SingleProperty from '../components/singleProperty';

export default function RealEstateAuction() {
  return (
    <Layout>
      <Seo
        title="Real Estate Auctions"
        description="Over 50 years experience in conducting appraisals, performing live and online auctions and facilitating the re-homing of your precious belongings."
      />

      <SingleProperty />
      {/* <FeatureAuction /> */}
      <PastAuctions />
      <RealEstate />
    </Layout>
  );
}
