import { Disclosure, Tab } from '@headlessui/react';
import {
  MinusIcon,
  PlusIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import {
  PlayIcon,
  ArrowTopRightOnSquareIcon,
  PhoneIcon,
} from '@heroicons/react/24/solid';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function PropertyCard({ property }) {
  return (
    <div>
      <div className="mx-auto max-w-2xl py-16 px-4 sm:px-6 sm:pt-24 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
          {/* Image gallery */}
          <Tab.Group as="div" className="flex flex-col-reverse">
            {/* Image selector */}
            {/* Checks if more than one image , then show thumbnails */}
            {property.images.length > 1 && (
              <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                <Tab.List className="grid grid-cols-4 gap-6">
                  {property.images.map((image) => (
                    <Tab
                      key={image.id}
                      className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                    >
                      {({ selected }) => (
                        <>
                          <span className="sr-only"> {image.name} </span>
                          <span className="absolute inset-0 overflow-hidden rounded-md">
                            <GatsbyImage
                              image={image.src.childImageSharp.gatsbyImageData}
                              alt={image.name}
                              className="h-full w-full object-cover object-center"
                            />
                          </span>
                          <span
                            className={classNames(
                              selected ? 'ring-indigo-500' : 'ring-transparent',
                              'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </Tab>
                  ))}
                </Tab.List>
              </div>
            )}
            <Tab.Panels className="aspect-w-16 aspect-h-9 w-full">
              {property.images.map((image) => (
                <Tab.Panel key={image.id}>
                  <GatsbyImage
                    image={image.src.childImageSharp.gatsbyImageData}
                    alt={image.name}
                    className="h-full w-full object-cover object-center sm:rounded-lg"
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>

          {/* Product info */}
          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
            {property.price && (
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                {property.price}
              </h1>
            )}
            <div className="my-2">
              <h2 className="sr-only">Product information</h2>
              <p className="text-3xl tracking-tight text-gray-900">
                {property.address}
              </p>
            </div>
            {property.mls_num && (
              <div>
                <h3 className="sr-only">MLS Number</h3>
                <div className="flex items-center">
                  <a href={property.url.mls} className="underline">
                    <span>MLS® Number: {property.mls_num}</span>
                  </a>
                </div>
              </div>
            )}
            <div className="pt-3">
              <a
                href="#"
                className="inline-flex items-center rounded-full bg-gray-900 p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base"
              >
                <span className="rounded-full bg-green-500 px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                  {property.bidding}
                </span>
                <ChevronRightIcon
                  className="ml-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                <span className="mr-2 text-sm">{property.close_date}</span>
              </a>
            </div>

            <div className="mt-6">
              <h3 className="sr-only">Description</h3>

              <div
                className="space-y-6 text-base text-gray-700"
                dangerouslySetInnerHTML={{ __html: property.description }}
              />
            </div>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
              <a
                href={property.url.auction}
                className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-brand-red-800 py-3 px-8 text-base font-medium uppercase text-white hover:bg-brand-red-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
              >
                <ArrowTopRightOnSquareIcon
                  className="mr-2 h-6 w-6 flex-shrink-0 text-white"
                  aria-hidden="true"
                />
                View Property
              </a>
              {property.url.video ? (
                <a href={property.url.video}>
                  <button
                    type="button"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-gray-900 py-3 px-4 text-base font-medium text-white hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-brand-red-800 focus:ring-offset-2 focus:ring-offset-gray-50"
                  >
                    <PlayIcon
                      className="mr-2 h-6 w-6 flex-shrink-0 text-brand-red-800"
                      aria-hidden="true"
                    />
                    Watch Video Tour
                  </button>
                </a>
              ) : (
                <a href="tel:519-282-8044">
                  <button
                    type="button"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-gray-900 py-3 px-4 text-base font-medium text-white hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-brand-red-800 focus:ring-offset-2 focus:ring-offset-gray-50"
                  >
                    <PhoneIcon
                      className="mr-2 h-6 w-6 flex-shrink-0 text-white"
                      aria-hidden="true"
                    />
                    Call Mark: 519-282-8044
                  </button>
                </a>
              )}
            </div>

            {property.details && (
              <section aria-labelledby="details-heading" className="mt-12">
                <h2 id="details-heading" className="sr-only">
                  Additional details
                </h2>

                <div className="divide-y divide-gray-200 border-t">
                  {property.details.map((detail) => (
                    <Disclosure as="div" key={detail.name}>
                      {({ open }) => (
                        <>
                          <h3>
                            <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                              <span
                                className={classNames(
                                  open ? 'text-brand-red-800' : 'text-gray-900',
                                  'text-xl font-medium '
                                )}
                              >
                                {detail.name}
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel
                            as="div"
                            className="prose prose-lg pb-6"
                          >
                            <ul role="list">
                              {detail.items.map((item) => (
                                <li key={item}>{item}</li>
                              ))}
                            </ul>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </div>
              </section>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
